
import { defineComponent } from "vue";
import CalendarEvent from "../CalendarEvent.vue";
import UserService from "../../services/UserService";
import EventService from "../../services/EventService";
import { UserLocation, SessionSelected, Event, Team } from "../../types";
import { TYPE, useToast } from "vue-toastification";
import refreshMixin from "@/mixins/refreshMixin";
import { AttendingStatus } from "../-types";

export default defineComponent({
  mixins: [refreshMixin],
  data() {
    const rightNow = Date.now();

    // const tomorrowAtFiveString: string = getTomorrowAtFiveString();
    // should change this on resize event but meh for now
    let innerWidth = window.innerWidth;
    const A_DAY_IN_MS = 86400000;
    return {
      event: null as Event,
      A_DAY_IN_MS,
      attendingStatus: 0 as AttendingStatus,
      getOldEventsLoading: false as boolean,
      getOldEvents: false as boolean,
      eventsLoaded: false as boolean,
      showPast: false as boolean,
      showCancelled: false as boolean,
      message: "" as string,
      showReturnToGetStartedButton: false as boolean,
      rightNow,
      innerWidth,
      startDateTime: "" as string | null,
      choiceVoteEndDateTime: "" as string,
      restaurantVoteEndDateTime: "" as string,
      votingDatesDisabled: true as boolean,
      locations: null as UserLocation[] | null,
      eventTeam: "" as string,
      location: "" as string,
      eventName: "" as string,
      sessionSelected: "" as SessionSelected,
      teamMembers: [] as string[],
      getStarted: false as boolean,
      team: null as Team,
      problemWithVenue: false as boolean,
    };
  },
  created() {
    this.$store.dispatch("setLoading", true);
  },
  async mounted() {
    try {
      await this.getEventDetails();
    } catch (e) {
      console.log(e);
    } finally {
      this.$store.dispatch("setLoading", false);
    }
  },
  methods: {
    async refresh() {
      await this.getEventDetails();
      this.completeRefresh();
    },
    async rsvpChanged({ checked }) {
      try {
        const checkedStatus = checked ? 1 : -1;
        const response = await UserService.changeRSVP(
          checkedStatus,
          this.$route.params.eventId
        );
        this.attendingStatus = response.data.attendingStatus;
      } catch (e) {
        console.log(e);
      }
    },
    async cancelEvent() {
      try {
        const response = await EventService.cancelEvent(
          this.$route.params.eventId,
          this.$store.state.userFirebaseUid
        );
        if (response.data.msg) {
          this.message = response.data.msg;
          const toast = useToast();
          toast(this.message, {
            timeout: 5000,
            type: TYPE.ERROR,
            hideProgressBar: true,
          });
        } else {
          this.getEventDetails();
          const toast = useToast();
          toast("Event successfully canceled", {
            timeout: 5000,
            type: TYPE.SUCCESS,
            hideProgressBar: true,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getEventDetails() {
      try {
        const response = await EventService.getEventDetails(
          this.$route.params.eventId
        );
        this.event = response.data.event;
        this.attendees = response.data.attendees;
        this.team = response.data.team;
        this.problemWithVenue = response.data.problemWithVenue;
        this.attendingStatus =
          this.attendees.find(
            (a) => a.userFirebaseUid === this.$store.state.userFirebaseUid
          )?.attendingStatus ?? 0;
      } catch (e) {
        console.log(e);
      } finally {
        this.eventsLoaded = true;
      }
    },
  },
  components: {
    CalendarEvent,
  },
});
