
import { defineComponent, PropType } from "vue";
import EventService from "@/services/EventService";
import { EventStatus, AttendingStatus, EventRestaurantDetails } from "./-types";
import Overlay from "./Overlay.vue";
import { showAttendeesModal } from "./Modals/ModalController";
import { googleUrl, sendGtagEvent } from "../common/common";
import { getGoogleMapsUrlFromCoordinates } from "@/common/common";
import "add-to-calendar-button";
import dayjs from "dayjs";
import { TYPE, useToast } from "vue-toastification";

export default defineComponent({
  emits: ["rsvp-changed", "cancel-event"],
  props: {
    teamName: { type: String, required: true },
    eventId: { type: Number, required: true },
    eventName: { type: String, required: true },
    creator: { type: String, required: true },
    eventDateTime: { type: [Date, String], required: true },
    restaurantVoteEndDateTime: { type: String, required: true },
    winningRestaurantDetails: {
      type: Object as PropType<EventRestaurantDetails> | null,
    },
    restaurant: { type: [String, null] },
    attendingStatus: {
      type: Number as PropType<AttendingStatus>,
      required: true,
    },
    eventStatus: { type: String as PropType<EventStatus>, required: true },
    cancelled: { type: Boolean, required: true },
    cancelledBy: { type: [String, null] },
    showPast: { type: Boolean, required: true },
    showCancelled: { type: Boolean, required: true },
    problemWithVenue: { type: Boolean, required: true },
    radius: { type: String, required: true },
    locationNickname: { type: String },
    lat: { type: String },
    long: { type: String },
  },
  mounted() {
    this.isMounted = true;
    this.$nextTick(() => {
      if (this.attendingStatus === 0) {
        this.$refs[this.rsvpRef].indeterminate = true;
      } else if (this.attendingStatus === 1) {
        this.$refs[this.rsvpRef].checked = true;
      } else {
        this.$refs[this.rsvpRef].checked = false;
      }
    });
  },
  data() {
    const coordsUrl = getGoogleMapsUrlFromCoordinates(this.lat, this.long);
    const rightNow = Date.now();
    return {
      coordsUrl,
      rightNow,
      isMounted: false as boolean,
      attendeesLoading: false as boolean,
      attendees: [] as string[],
    };
  },
  computed: {
    rsvpRef() {
      return "rsvp" + this.eventId;
    },
    voteNowButtonRoute() {
      return { name: "RestaurantSession", params: { eventId: this.eventId } };
    },
    restaurantPollButtonClass() {
      return this.restaurant
        ? "btn btn-outline-dark text-light-hover btn-sm not-too-long-button text-dark rounded-2 mt-1 me-1 text-decoration-none"
        : "btn btn-outline-success text-light-hover btn-sm text-success not-too-long-button rounded-2 mt-1 me-1 text-decoration-none";
    },
    timeUntilRestaurantVoteEnds() {
      if (this.eventStatus === "voting-ended") {
        return "";
      }
      const endTime = dayjs(this.restaurantVoteEndDateTime);
      if (dayjs().isBefore(endTime)) {
        return (
          " - Poll closes " +
          endTime.format("dddd, MMM D, YYYY") +
          " at " +
          endTime.format("h:mm A")
        );
      } else {
        return "";
      }
    },
    overlayColor() {
      if (this.cancelled) {
        return "red";
      } else if (
        new Date(this.eventDateTime).getTime() <
          new Date(this.rightNow).getTime() ||
        this.attendingStatus === -1
      ) {
        return "gray";
      } else return "";
    },
    overlayClass() {
      if (this.cancelled) {
        return "overlay cancelled";
      } else if (this.attendingStatus === -1) {
        return "overlay not-attending";
      }
      // TODO add in logic to add event-over class if combined date and time is greater than rightNow
      else return "";
    },
    formattedEventDate() {
      return this.eventDateTime
        ? dayjs(this.eventDateTime).format("dddd, MMM D, YYYY")
        : "";
    },
    formattedEventTime() {
      return this.eventDateTime
        ? new Date(this.eventDateTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        : "";
    },
    attendingOrNotClass() {
      let classes = ["form-check-label", "fw-bold"];
      if (this.attendingStatus === 0) {
        classes.push("text-danger");
      } else if (this.attendingStatus === 1) {
        classes.push("text-success");
      } else if (this.attendingStatus === -1) {
        classes.push("text-muted");
      }
      return classes;
    },
    attendingOrNot() {
      if (this.attendingStatus === 0) {
        return "You haven't responded yet";
      } else if (this.attendingStatus === 1) {
        return "You're going!";
      } else if (this.attendingStatus === -1) {
        return "You're not going";
      } else {
        throw new Error("Bad attending status");
      }
    },
  },
  methods: {
    getGoogleMapsUrlFromCoordinates,
    async endVoting() {
      if (this.winningRestaurant) {
        const toast = useToast();
        toast(
          "Voting has already concluded, winner is " + this.winningRestaurant,
          {
            timeout: 2500,
            type: TYPE.INFO,
          }
        );
      } else {
        if (
          confirm(
            "This will end the poll and decide a winner based on current votes only. Are you sure?"
          )
        ) {
          this.$nextTick(async () => {
            await this.actuallyEndVoting();
          });
        }
      }
    },
    async actuallyEndVoting() {
      const toast = useToast();
      const response = await EventService.endVoting(
        this.eventId,
        this.$store.state.userFirebaseUid,
        "restaurant"
      );
      toast(response.data.msg, {
        timeout: 2500,
        type: TYPE.INFO,
      });
    },
    addedToCalendar() {
      sendGtagEvent(this.$gtag, "added_to_calendar", {
        eventLabel: "added_to_calendar",
      });
    },
    dayjs,
    googleUrl,
    showAttendeesModal,
    rsvpChanged(event) {
      this.$nextTick(() => {
        this.$emit("rsvp-changed", {
          checked: event.target.checked,
        });
      });
    },
    async getAttendees() {
      try {
        this.attendeesLoading = true;
        const response = await EventService.getEventAttendees(this.eventId);
        this.attendees = response.data.attendees;
        this.attendeesLoading = false;
        this.showAttendeesModal(this.attendees);
      } catch (error) {
        console.log("error: " + error);
      }
    },
    async cancelEvent(): Promise<void> {
      if (await confirm("This will cancel the event. Are you sure?")) {
        this.$nextTick(() => {
          this.$emit("cancel-event", {
            eventId: this.eventId,
          });
        });
      }
    },
  },
  components: { Overlay },
});
