import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = {
  key: 0,
  class: "col-sm-12 col-lg-8 align-self-center mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalendarEvent = _resolveComponent("CalendarEvent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.event !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_CalendarEvent, {
                teamName: _ctx.team.teamName,
                eventName: _ctx.event.eventName,
                eventId: _ctx.event.eventId,
                creator: _ctx.event.createdBy,
                eventDateTime: _ctx.event.eventStartDateTime,
                choiceVoteEndDateTime: _ctx.event.choiceVoteEndDateTime,
                restaurantVoteEndDateTime: _ctx.event.restaurantVoteEndDateTime,
                restaurant: _ctx.event.winningRestaurant,
                choice: _ctx.event.winningChoice,
                attendingStatus: _ctx.attendingStatus,
                eventStatus: _ctx.event.currentStatus,
                winningChoice: _ctx.event.winningChoice,
                winningRestaurantDetails: _ctx.event.winningRestaurantDetails,
                cancelled: _ctx.event.cancelled,
                cancelledBy: _ctx.event.cancelledBy,
                showCancelled: _ctx.showCancelled,
                radius: _ctx.event.radius,
                lat: _ctx.event.sessionLat,
                long: _ctx.event.sessionLong,
                locationNickname: _ctx.event.locationNickname,
                problemWithVenue: _ctx.problemWithVenue,
                showPast: _ctx.showPast,
                onCancelEvent: _ctx.cancelEvent,
                onRsvpChanged: _ctx.rsvpChanged
              }, null, 8, ["teamName", "eventName", "eventId", "creator", "eventDateTime", "choiceVoteEndDateTime", "restaurantVoteEndDateTime", "restaurant", "choice", "attendingStatus", "eventStatus", "winningChoice", "winningRestaurantDetails", "cancelled", "cancelledBy", "showCancelled", "radius", "lat", "long", "locationNickname", "problemWithVenue", "showPast", "onCancelEvent", "onRsvpChanged"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}